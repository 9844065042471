import IndexPage from '../pages/IndexPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ResultPage from '../pages/ResultPage';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/results' element={<ResultPage/>}/>
                <Route path='/' element={<IndexPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
