import React, {MouseEvent, ReactElement, useState} from 'react';

type DropdownProps<EnumType extends string | number | symbol> = {
    selected: EnumType | undefined;
    setSelected: (value: EnumType) => void;
    placeholder: string;
    options: EnumType[];
    parseStringToEnum: (value: string) => EnumType,
    translations: Record<EnumType, string>
};

function Dropdown<EnumType extends string | number | symbol>({
                                                                 selected,
                                                                 setSelected,
                                                                 options,
                                                                 placeholder,
                                                                 parseStringToEnum,
                                                                 translations
                                                             }: DropdownProps<EnumType>) {
    const [isActive, setIsActive] = useState(false);

    const placeholderElement: ReactElement = (
        <span className='dropdown__btn-name dropdown__btn-name--selected'>
      {placeholder}
    </span>
    );

    const dropdownBtnActive = isActive
        ? 'dropdown__btn dropdown__btn--active'
        : 'dropdown__btn';

    const selectOption = selected ?
        <span className='dropdown__span--selected'>{translations[selected]}</span>
        : placeholderElement;

    const arrowRotation = isActive
        ? 'dropdown__btn-icon dropdown__btn-icon--active'
        : 'dropdown__btn-icon';

    const dropdownContentShow = isActive
        ? 'dropdown__content dropdown-content--active'
        : 'dropdown__content';

    function handleClick(): void {
        setIsActive(!isActive);
    }

    function handleClose(): void {
        setIsActive(false);
    }

    function handleOptionClick(event: MouseEvent<HTMLDivElement>): void {
        const target: HTMLDivElement = event.target as HTMLDivElement
        const option = target.getAttribute('data-option') ?? ''
        setSelected(parseStringToEnum(option));
        handleClose();
    }

    function OptionComponents() {
        const dropdownOptions = (options || []).map((option, index) => (
            <div
                key={index}
                onClick={handleOptionClick}
                data-option={option}
                className='dropdown__item'
            >
                {translations[option]}
            </div>
        ));

        return <>{dropdownOptions}</>
    }

    return (
        <div className='dropdown' tabIndex={0} onBlur={handleClose}>
            <div onClick={handleClick} className={dropdownBtnActive}>
                {selectOption}
                <img
                    className={arrowRotation}
                    src='assets/images/arrow-down.svg'
                    alt='Arrow icon'
                />
            </div>
            <div className={dropdownContentShow}><OptionComponents/></div>
        </div>
    );
}

export default Dropdown;
