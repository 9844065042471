import {useEffect, useState} from 'react';
import {Student} from '../models/Student';
import {ApiResponse} from '../models/ApiResponse';
import studentService from '../services/studentService';
import timeService from '../services/timeService';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {translations} from '../constants';
import {parseGameStringToEnum} from '../helpers';

function ResultPage(): JSX.Element {
    const [students, setStudents] = useState<Student[]>([]);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    const game = searchParams.get('game') ?? undefined
    const gameTag: string = translations[parseGameStringToEnum(game ?? '')] ?? 'Svi'

    async function loadStudents() {
        const response: ApiResponse<Student[]> = await studentService.getAll(game);
        setStudents(response.data ?? []);
    }

    useEffect(() => {
        void loadStudents();
    }, [])

    function handleNavigate(): void {
        navigate('/');
    }

    function StudentItems(): JSX.Element {
        return (
            <>
                {
                    students.map((student, index) => (
                        <li key={`${student.email}-${index}`} className='table__item'>
									<span className='table__item-left'>
										<span className='table__item-name'>{student.fullName}</span>
										<span className='table__item-email'>{student.email}</span>
									</span>
                            <span className='table__item-time'>{timeService.formatTime(student.result)}</span>
                        </li>
                    ))
                }
            </>
        )
    }

    return (
        <section className='table'>
            <div className='wrap'>
                <div className='table__content'>
                    <div className='table__header'>
                        <h2 className='table__title'>Rang lista</h2>
                        <img src='assets/images/vega-logo-rang-lista.svg' alt='' className='table__img'/>
                        <span className='table__game'>{gameTag}</span>
                    </div>
                    <div className='table__info'>
                        <div className='table__list-wrap'>
                            <ul className='table__list'>
                                <StudentItems/>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table__btn-wrap'>
                <button className='btn table__btn' type='button' onClick={handleNavigate}>
                    Nazad na početak
                </button>
            </div>
        </section>
    )
}

export default ResultPage;
