import {ApiResponse} from '../models/ApiResponse';
import {Student} from '../models/Student';
import {StudentResponse} from '../models/SutdentResponse';
import {StudentRequest} from '../models/StudentRequest';
import {API_URL} from '../constants';

async function formatStudentResponse(response: Response): Promise<ApiResponse<Student>> {
    if (response.status >= 400) {
        return {hasError: true};
    }

    const body: StudentResponse = (await response.json()) as StudentResponse;
    return {
        hasError: false,
        data: {
            fullName: body.full_name,
            result: body.result,
            email: body.email,
            year: body.year,
            education: body.education,
            game: body.game
        }
    };
}

async function formatStudentsResponse(response: Response): Promise<ApiResponse<Student[]>> {
    if (response.status >= 400) {
        return {hasError: true};
    }

    const body: StudentResponse[] = (await response.json()) as StudentResponse[];
    return {
        hasError: false,
        data: body.map(studentResponse => ({
            fullName: studentResponse.full_name,
            result: studentResponse.result,
            email: studentResponse.email,
            year: studentResponse.year,
            education: studentResponse.education,
            game: studentResponse.game
        }))
    };
}

async function getAll(game?: string): Promise<ApiResponse<Student[]>> {
    const gameParam = game ? `?game=${game}` : ''
    const response: Response = await fetch(`${API_URL}/api/students${gameParam}`);
    return formatStudentsResponse(response);
}

async function getOne(email: string): Promise<ApiResponse<Student>> {
    const response: Response = await fetch(`${API_URL}/api/students?email=${email}`);
    return formatStudentResponse(response);
}

async function add(student: Student): Promise<ApiResponse<Student>> {
    const body: StudentRequest = {
        full_name: student.fullName,
        result: student.result,
        email: student.email,
        year: student.year,
        education: student.education,
        game: student.game
    };
    const response: Response = await fetch(`${API_URL}/api/students`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    });
    return formatStudentResponse(response);
}

interface StudentService {
    getOne: (email: string) => Promise<ApiResponse<Student>>;
    add: (student: Student) => Promise<ApiResponse<Student>>;
    getAll: (game?: string) => Promise<ApiResponse<Student[]>>;
}

const studentService: StudentService = {getOne, add, getAll};

export default studentService;
