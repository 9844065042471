import {useEffect, useState} from 'react';
import timeService from '../services/timeService';

interface StopwatchProps {
    handleStopwatchTime: (result: number) => Promise<void>;
}

function Stopwatch({handleStopwatchTime}: StopwatchProps): JSX.Element {
    const [time, setTime] = useState<number>(0);
    const [isStarted, setStarted] = useState<boolean>(false);

    useEffect(() => {
        let intervalId: NodeJS.Timer;
        if (isStarted) {
            intervalId = setInterval(() => setTime(time + 1), 10);
        }
        return () => clearInterval(intervalId);
    }, [time, isStarted])

    function handleStart(): void {
        setStarted(() => true)
    }

    function handleToggle(): void {
        const action: () => void = isStarted ? handleStop : handleStart;
        action();
    }

    async function handleStop(): Promise<void> {
        setStarted(false);
        await handleStopwatchTime(time);
    }

    function handleReset(): void {
        setStarted(false);
        setTime(0);
    }

    const buttonText: string = isStarted ? 'Stop' : 'Start';
    const formattedTime: string = timeService.formatTime(time);

    return (
        <section className='watch'>
            <div className='wrap'>
                <div className='watch__content'>
                    <span className='watch__num'>{formattedTime}</span>
                    <div className='watch__controls'>
                        <button type='button' className={`btn-round btn-round--grey watch__btn ${!isStarted ? 'watch-btn--disabled' : ''}`}
                            onClick={handleReset} disabled={!isStarted}>Reset
                        </button>
                    <button type='button' className={`btn-round btn-round--purple watch__btn ${isStarted ? 'watch-btn--active' : ''}`}
                            onClick={handleToggle}>{buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stopwatch;
