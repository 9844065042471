import {Student} from '../models/Student';
import {ChangeEvent, CSSProperties, FormEvent, useState} from 'react';
import {EmailError} from '../models/EmailError';
import studentService from '../services/studentService';
import {ApiResponse} from '../models/ApiResponse';
import Dropdown from './dropdown/Dropdown';
import {Game} from '../models/Game';
import {parseGameStringToEnum} from '../helpers';
import {translations} from '../constants';

const DROPDOWN_PLACEHOLDER: string = 'Odaberi igru'
const DROPDOWN_OPTIONS: Game[] = [Game.Tangram, Game.Rubiks_cube];

interface RegisterFormProps {
    student: Student;
    handleRegistration: (student: Student) => void;
}

function RegisterForm({
                          student,
                          handleRegistration,
                      }: RegisterFormProps): JSX.Element {
    const [formData, setFormData] = useState<Student>(student);
    const [emailError, setEmailError] = useState<EmailError>(EmailError.NONE);
    const [dropdownSelection, setDropdownSelection] = useState<Game | undefined>();

    function handleFormInputChange(event: ChangeEvent<HTMLInputElement>): void {
        const {name, value} = event.target;
        setFormData((currentFormData) => ({...currentFormData, [name]: value}));
    }

    function isEmailValid(email: string): boolean {
        const regex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function handleEmailInputChange(event: ChangeEvent<HTMLInputElement>): void {
        const {name, value} = event.target;
        const isValid: boolean = isEmailValid(value);

        const error: EmailError = isValid ? EmailError.NONE : EmailError.INVALID;

        setEmailError(error);
        setFormData((currentFormData) => ({...currentFormData, [name]: value}));
    }

    function handleDropdownSelectionUpdate(value: Game): void {
        setDropdownSelection(value);
        formData.game = value
    }

    function isFormValid(): boolean {
        const {fullName, email, education} = formData;

        if (!fullName) {
            return false;
        }

        if (!isEmailValid(email)) {
            return false;
        }

        if (!education) {
            return false;
        }

        if (!dropdownSelection) {
            return false;
        }

        return true;
    }

    async function handleSubmit(event: FormEvent): Promise<void> {
        event.preventDefault();

        const response: ApiResponse<Student> = await studentService.getOne(
            formData.email
        );

        if (!response.hasError) {
            setEmailError(EmailError.NOT_UNIQUE);
            return;
        }

        handleRegistration(formData);
    }

    const hideStyle: CSSProperties = {display: 'none'};
    const showStyle: CSSProperties = {display: 'block'};
    const invalidEmailStyle: CSSProperties =
        emailError === EmailError.INVALID ? showStyle : hideStyle;
    const uniqueEmailStyle: CSSProperties =
        emailError === EmailError.NOT_UNIQUE ? showStyle : hideStyle;

    const isSubmitDisabled: boolean = !isFormValid();

    return (
        <>
            <section className='loader'>
                <img
                    className='registration-form__logo loader__img--3'
                    src='assets/images/vega-logo.svg'
                    alt='Vega logo'
                />
            </section>
            <section className='registration-form'>
                <div className='registration-form__decoration-wrap'>
                    <img
                        className='registration-form__logo'
                        src='assets/images/decoration.svg'
                        alt=''
                    />
                </div>
                <div className='wrap-md'>
                    <div className='registration-form__logo-wrap'>
                        <img
                            className='registration-form__logo'
                            src='assets/images/new-logo.svg'
                            alt=''
                        />
                    </div>
                    <form className='registration-form__app-form'>
                        <div className='registration-form__field'>
                            <label
                                className='registration-form__label sr-only'
                                htmlFor='name'
                            >
                                name
                            </label>
                            <input
                                className='registration-form__input'
                                id='name'
                                type='text'
                                placeholder='Ime i prezime*'
                                name='fullName'
                                value={formData.fullName}
                                onChange={handleFormInputChange}
                                required={true}
                            />
                            <span className='registration-form__input-placeholder'>
                Ime i prezime
              </span>
                        </div>
                        <div className='registration-form__field'>
                            <label
                                className='registration-form__label sr-only'
                                htmlFor='email'
                            >
                                email
                            </label>
                            <input
                                className={`registration-form__input ${
                                    emailError ? 'registration-form__input--error' : ''
                                }`}
                                id='email'
                                type='email'
                                placeholder='Email*'
                                name='email'
                                value={formData.email}
                                onChange={handleEmailInputChange}
                                required={true}
                            />
                            <span className='registration-form__input-placeholder'>
                Email
              </span>
                            <span
                                style={invalidEmailStyle}
                                className='registration-form__error'
                                id='form-error'
                            >
                Unesi validnu email adresu
              </span>
                            <div
                                id='form-tooltip'
                                style={uniqueEmailStyle}
                                className='registration-form__tooltip'
                            >
                                <span className='registration-form__triangle font-ico-triangle'/>
                                <p className='registration-form__tooltip-text'>
                                    Već si odigrao/la igricu. Dodatne aktivacije možeš pronaći na našem štandu. :)
                                </p>
                            </div>
                        </div>
                        <div className='registration-form__field'>
                            <label
                                className='registration-form__label sr-only'
                                htmlFor='faculty'
                            >
                                faculty
                            </label>
                            <input
                                className='registration-form__input'
                                id='faculty'
                                type='text'
                                placeholder='Fakultet*'
                                name='education'
                                value={formData.education}
                                onChange={handleFormInputChange}
                                required={true}
                            />
                            <span className='registration-form__input-placeholder'>
                Fakultet
              </span>
                        </div>
                        <div className='registration-form__field'>
                            <label
                                className='registration-form__label sr-only'
                                htmlFor='year'
                            >
                                year
                            </label>
                            <input
                                className='registration-form__input'
                                id='year'
                                type='number'
                                placeholder='Godina studija*'
                                name='year'
                                onChange={handleFormInputChange}
                                required={true}
                            />
                            <span className='registration-form__input-placeholder'>
                Godina studija
              </span>
                        </div>
                        <Dropdown<Game>
                            selected={dropdownSelection}
                            setSelected={handleDropdownSelectionUpdate}
                            options={DROPDOWN_OPTIONS}
                            placeholder={DROPDOWN_PLACEHOLDER}
                            parseStringToEnum={parseGameStringToEnum}
                            translations={translations}/>
                        <button
                            className={`registration-form__btn ${
                                isSubmitDisabled ? 'registration-form__btn--disabled' : ''
                            }`}
                            type='submit'
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled}
                        >
                            Potvrdi
                        </button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default RegisterForm;
