import {useState} from 'react';
import {StudentState} from '../models/StudentState';
import {Step} from '../models/Step';
import RegisterForm from '../componenets/RegisterForm';
import {Student} from '../models/Student';
import Stopwatch from '../componenets/Stopwatch';
import studentService from '../services/studentService';
import timeService from '../services/timeService';
import {useNavigate} from 'react-router-dom';
import {Game} from '../models/Game';

function IndexPage(): JSX.Element {
    const [studentState, setStudentState] = useState<StudentState>({
        data: {
            education: '',
            email: '',
            fullName: '',
            result: 0,
            year: new Date().getFullYear(),
            game: Game.Tangram
        },
        step: Step.REGISTRATION
    })

    const navigate = useNavigate();

    function handleNavigate(): void {
        navigate(`/results?game=${studentState.data.game}`);
    }

    function handleRegistration(student: Student): void {
        setStudentState(() => ({step: Step.STOPWATCH, data: student}));
    }

    async function handleStopwatchTime(result: number): Promise<void> {
        setStudentState((currentStudentState) => ({
            step: Step.RESULT_PAGE,
            data: {...currentStudentState.data, result}
        }));
        await studentService.add({...studentState.data, result});
    }

    if (studentState.step === Step.REGISTRATION) {
        return (
            <RegisterForm student={studentState.data} handleRegistration={handleRegistration}/>
        );
    }

    if (studentState.step === Step.STOPWATCH) {
        return (
            <Stopwatch handleStopwatchTime={handleStopwatchTime}/>
        )
    }

    const timeFormatted: string = timeService.formatTime(studentState.data.result);

    return (
        <section className='results'>
            <img src='assets/images/results-decoration.svg' alt='' className='results__img'/>
            <div className='wrap'>
                <div className='results__content'>
                    <div className='results__info'>
                        <span className='results__pretitle'>Bravo!</span>
                        <span className='results__title'>Tvoj rezultat je:</span>
                        <span className='results__num'>{timeFormatted}</span>
                        <button type='button' className='btn results__btn' onClick={handleNavigate}>Rang lista</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IndexPage;
